<template>
  <v-app>
    <v-main>
      <v-row class="no-gutter">
        <!-- The image half -->
        <v-col lg="6" cols="sm" class="d-none d-md-flex bg-image"></v-col>
        <!-- The content half -->
        <v-col lg="6" cols="sm">
          <div class="login d-flex align-items-center py-5">
            <!-- Demo content-->
            <v-container>
              <v-row>
                <v-col lg="10" class="col-xl-7 mx-auto  mt-5">
                  <h3 class="font-weight-light display-3 mt-12">API</h3>
                  <p class="text-muted mb-4">
                    ACCESS ALL FREELANCERS QUICKLY AND EASILY
                  </p>
                  <div class="form-group mb-3">
                    <v-text-field
                      label="Full Name"
                      autofocus=""
                      v-model="userName"
                      autocomplete="name"
                      outlined
                      dense
                    />
                  </div>
                  <div class="form-group mb-3">
                    <v-text-field
                      id="inputEmail"
                      label="Email address"
                      v-model="userEmail"
                      autocomplete="email"
                      outlined
                      dense
                    />
                  </div>
                  <div class="form-group mb-3">
                    <v-text-field
                      id="inputPassword"
                      type="password"
                      label="Password"
                      v-model="userPassword"
                      autocomplete="current-password"
                      outlined
                      dense
                    />
                  </div>
                  <div class="custom-control custom-checkbox mb-3">
                    <input
                      id="customCheck1"
                      type="checkbox"
                      checked
                      class="custom-control-input mx-2"
                    />
                    <label for="customCheck1" class="custom-control-label"
                      >Remember password</label
                    >
                  </div>

                  <v-btn
                    @click="registerUser"
                    class="primary btn-block text-uppercase mb-2 rounded-pill shadow-sm"
                  >
                    Register
                  </v-btn>
                  <div class="text-center d-flex justify-content-between mt-4">
                    <p>
                      <a href="#/login" class="font-italic text-muted"
                        >Have an account?.</a
                      >
                    </p>
                  </div>
                  <div class="footer"></div>
                </v-col>
              </v-row>
            </v-container>
            <!-- End -->
          </div>
        </v-col>
        <!-- End -->
      </v-row>
    </v-main>
  </v-app>
</template>
<style>
.login,
.image {
  min-height: 100vh;
}
.bg-image {
  background-image: url("../../../assets/img/login-split.jpg");
  background-size: cover;
  background-position: center center;
}
</style>
<script>
import * as d3 from "d3";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import Vue from "vue";
import axios from "axios";

export default {
  data: function() {
    return {
      userEmail: "",
      userName: "",
      userPassword: "",
    };
  },

  beforeCreate() {
    this.$store.dispatch("signOut");
  },

  computed: {
    ...mapState(["loginError", "currentUser"]),
    ...mapGetters(["loginError", "currentUser"]),
  },

  watch: {
    currentUser(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: "Success",
          text: "Welcome Back",
        });
        this.$router.push("/home");
      }
    },

    loginError(val) {
      if (val !== null) {
        Vue.notify({
          group: "foo",
          title: "Login Error",
          text: val,
        });
      }
    },
  },

  created() {},

  methods: {
    registerUser() {
      this.$store.dispatch("registerUser", {
        userName: this.userName,
        email: this.userEmail,
        password: this.userPassword,
      });
    },
  },
};
</script>
